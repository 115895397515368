import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../components/languageUtils';
import sanityClient from '../../sanity';
import imageUrlBuilder from '@sanity/image-url';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Project.css';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const Project = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const getProjectPath = (slug) => {
    if (language === 'sq') {
      return `/sq/projekte/${slug}`;
    }
    return `/en/projects/${slug}`;
  };

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    setLoading(true);
    sanityClient
      .fetch(
        `*[_type == "projectsPost" && language == $language && isCall == true && category == "ongoing"] | order(projectDate desc) {
          _id,
          title,
          slug,
          homepageImage {
            asset->{
              _id,
              url
            },
            alt
          },
          category,
          projectDate
        }`,
        { language }
      )
      .then((data) => {
        console.log('Fetched projects:', data);
        if (data.length === 0) {
          setError(language === 'en' ? 'No ongoing calls found.' : 'Nuk u gjetën thirrje në vazhdim.');
        } else {
          setProjects(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Sanity fetch error:', err);
        setError(language === 'en' ? 'Failed to load projects.' : 'Dështoi në ngarkimin e projekteve.');
        setLoading(false);
      });
  }, [language]);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth <= 1024;
      setSlidesPerView(smallScreen ? (window.innerWidth <= 768 ? 1 : 2) : 2);
      setIsSmallScreen(smallScreen);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let interval;
    if (isSmallScreen && !isDragging && projects.length > 0) {
      interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % (projects.length - slidesPerView + 1));
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isSmallScreen, projects.length, slidesPerView, isDragging]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % (projects.length - slidesPerView + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + (projects.length - slidesPerView + 1)) % (projects.length - slidesPerView + 1));
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 3;
    if (walk > 50) {
      prevSlide();
      setIsDragging(false);
    } else if (walk < -50) {
      nextSlide();
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleProjectClick = (slug) => {
    navigate(getProjectPath(slug.current));
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const slideVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="project-container" style={{ backgroundImage: `url(../sea.jpg)` }}>
        <div className="project-overlay"></div>
        <motion.div className="project-content" variants={containerVariants}>
          <motion.div 
            className="project-red-accent" 
            variants={itemVariants} 
            initial={{ width: 0 }}
            animate={{ width: '50px' }}
            transition={{ duration: 0.8, ease: 'easeInOut' }}
          />
          <motion.h2 
            className="ProjectsHomeAic-heading1 text-4xl md:text-5xl lg:text-5xl" 
            variants={itemVariants}
          >
            {language === 'en' ? 'DISCOVER ALBANIA\'S POTENTIAL' : 'ZBULONI POTENCIALIN E SHQIPËRISË'}
          </motion.h2>
          <motion.h3 
            className="ProjectsHomeAic-heading2 text-2xl md:text-3xl lg:text-1xl" 
            variants={itemVariants}
          >
            {language === 'en' ? 'OPEN CALL TO INVEST IN OUR PROJECTS TODAY' : 'THIRRJE E HAPUR PËR TË INVESTUAR NË PROJEKTET TONA SOT'}
          </motion.h3>
          <motion.p className="ProjectsHomeAic-paragraph" variants={itemVariants}>
            {language === 'en' 
              ? 'Unlock the immense opportunities Albania has to offer, from its untapped natural resources to its rapidly growing sectors in tourism, infrastructure, and renewable energy. Our projects are designed to propel Albania into the future, and we are inviting investors to be a part of this exciting journey. By investing today, you will join a forward-thinking community of innovators and entrepreneurs, ensuring sustainable growth and impactful returns.'
              : 'Zbuloni mundësitë e mëdha që Shqipëria ka për të ofruar, nga burimet e saj natyrore të pashfrytëzuara deri te sektorët e saj në rritje të shpejtë në turizëm, infrastrukturë dhe energji të rinovueshme. Projektet tona janë dizajnuar për të shtyrë Shqipërinë drejt së ardhmes dhe ne po ftojmë investitorët të jenë pjesë e kësaj udhëtimi emocionues. Duke investuar sot, ju do t\'i bashkoheni një komuniteti vizionar inovatorësh dhe sipërmarrësish, duke siguruar rritje të qëndrueshme dhe kthime me ndikim.'}
          </motion.p>

          {loading ? (
            <motion.div variants={itemVariants} className="text-center py-8">
              {language === 'en' ? 'Loading ongoing calls...' : 'Duke ngarkuar thirrjet në vazhdim...'}
            </motion.div>
          ) : error || projects.length === 0 ? (
            <motion.div 
              className="no-open-calls"
              variants={itemVariants}
            >
              <h3>
                {language === 'en' 
                  ? 'There are currently no open calls'
                  : 'Aktualisht nuk ka thirrje të hapura'}
              </h3>
              <p>
                {language === 'en'
                  ? 'Please check back later for new investment opportunities.'
                  : 'Ju lutemi kontrolloni më vonë për mundësi të reja investimi.'}
              </p>
            </motion.div>
          ) : (
            <motion.div 
              className="project-slider" 
              {...handlers} 
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              {!isSmallScreen && (
                <button onClick={prevSlide} className="project-arrow left">
                  &#10094;
                </button>
              )}
              <div 
                className="project-slides-container" 
                style={{ transform: `translateX(-${currentSlide * (100 / slidesPerView)}%)` }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
              >
                {projects.map((project, index) => (
                  <motion.div 
                    key={project._id} 
                    className="project-slide"
                    variants={slideVariants}
                    custom={index}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                    onClick={() => handleProjectClick(project.slug)}
                  >
                    <div 
                      className="project"
                      style={{ 
                        backgroundImage: `url('${project.homepageImage?.asset?.url}')`,
                        cursor: 'pointer'
                      }}
                    />
                  </motion.div>
                ))}
              </div>
              {!isSmallScreen && (
                <button onClick={nextSlide} className="project-arrow right">
                  &#10095;
                </button>
              )}
            </motion.div>
          )}
        </motion.div>
      </div>

      <motion.div 
        className="project-investment-cta"
        variants={itemVariants}
      >
        <h3>
          {language === 'en' ? 'YOUR INVESTMENT IS SUPPORTED BY AIC!' : 'INVESTIMI JUAJ MBËSHTETET NGA AIC!'}
        </h3> 
        <Link 
          to={`/${language}${language === 'en' ? '/contact' : '/kontakt'}`} 
          className="project-invest-button"
        >
          {language === 'en' ? 'INVEST NOW' : 'INVESTO TANI'}
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default Project;