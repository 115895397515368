import React from 'react';
import { useTranslation } from 'react-i18next';
import { Network, Folder, Globe, PieChart, RefreshCw, Settings } from 'lucide-react';

const HeroSection = () => {
  const { t } = useTranslation(['homeHeader']);

  const features = [
    { Icon: Network, text: t('homeHeader:features.expertise') },
    { Icon: Folder, text: t('homeHeader:features.portfolio') },
    { Icon: Globe, text: t('homeHeader:features.solutions') },
    { Icon: PieChart, text: t('homeHeader:features.transparency') },
    { Icon: RefreshCw, text: t('homeHeader:features.partnerships') },
    { Icon: Settings, text: t('homeHeader:features.development') },
  ];

  return (
    <>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }

          @keyframes slideUp {
            from {
              transform: translateY(20px);
              opacity: 0;
            }
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }

          @keyframes slideRight {
            from {
              transform: translateX(-20px);
              opacity: 0;
            }
            to {
              transform: translateX(0);
              opacity: 1;
            }
          }

          .animate-fade {
            opacity: 0;
            animation: fadeIn 0.5s ease-out forwards;
          }

          .animate-slide-up {
            opacity: 0;
            animation: slideUp 0.5s ease-out forwards;
          }

          .animate-slide-right {
            opacity: 0;
            animation: slideRight 0.5s ease-out forwards;
          }
        `}
      </style>

      <div className="relative w-full text-white font-rubik">
        <div className="min-h-[650px] h-screen">
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/50 to-black/70 z-10"></div>
            <img 
              src="/Image2.jpg" 
              alt="Hero Background" 
              className="w-full h-full object-cover object-center"
              loading="eager"
            />
          </div>

          <div className="relative h-full flex flex-col justify-between z-20">
            <div className="flex-grow flex items-center -mt-60 px-4 sm:px-6 lg:px-8">
              <div className="w-[80%] md:w-[70%] mx-auto text-left">
                <p className="text-white font-semibold mb-2 text-sm sm:text-base lg:text-xl relative animate-slide-up"
                   style={{ animationDelay: '0.3s' }}>
                  {t('homeHeader:pages.home.subtitle')}
                  <span className="absolute bottom-[-15px] left-[0%] w-20 h-2.5 bg-red-600 animate-slide-right"
                        style={{ animationDelay: '0.6s' }}></span>
                </p>
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mt-8 animate-slide-up"
                    style={{ animationDelay: '0.5s' }}>
                  {t('homeHeader:pages.home.title')}
                </h1>
              </div>
            </div>

            <div className="relative mt-auto">
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full sm:w-[95%] lg:w-[90%] xl:w-[85%] 2xl:w-[80%] max-w-[1800px]">
                <div className="bg-red-600 rounded-t-2xl sm:rounded-t-3xl shadow-2xl animate-slide-up"
                     style={{ animationDelay: '0.7s' }}>
                  <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-0">
                    {features.map(({ Icon, text }, index) => (
                      <div 
                        key={index} 
                        className="relative p-4 sm:p-5 lg:p-6 xl:p-8 flex flex-col items-center justify-center animate-fade"
                        style={{ animationDelay: `${0.8 + index * 0.1}s` }}
                      >
                        <div className="mb-2 sm:mb-3">
                          <Icon className="w-6 h-6 sm:w-8 sm:h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12" />
                        </div>
                        
                        <span className="text-center text-xs sm:text-sm lg:text-base font-medium uppercase max-w-[120px] leading-tight">
                          {text}
                        </span>

                        {index !== features.length - 1 && (
                          <>
                            <div className="hidden sm:block absolute right-0 top-[20%] h-[60%] w-px bg-white/30"></div>
                            {index % 2 === 0 && index !== features.length - 2 && (
                              <div className="sm:hidden absolute right-0 top-[20%] h-[60%] w-px bg-white/30"></div>
                            )}
                            {index < (Math.ceil(features.length / 2) * 2) - 2 && (
                              <div className="sm:hidden absolute bottom-0 left-[10%] w-[80%] h-px bg-white/30"></div>
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;