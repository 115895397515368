// App.js
import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LanguageProvider, useLanguage, translateRoute } from './components/languageUtils';
import { GlobalLanguageProvider, GlobalLanguageContext } from './components/LanguageSwitcher';
import MainHeader from './components/MainHeader';
import HeaderHome from './Pages/Home/HeaderHome';
import Footer from './components/Footer';
import Loading from './components/Loading';

// Import your page components
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import WhyChooseAIC from './Pages/About/WhyChooseAIC';
import ByLaw from './Pages/About/ByLaw';
import Transparency from './Pages/About/Transparency';
import Contact from './Pages/Contact/Contact';
import Projects from './Pages/Projects/Projects';
import Ongoing from './Pages/Open Calls/Ongoing';
import ClosedCalls from './Pages/Open Calls/ClosedCalls';
import MeetTheStaff from './Pages/Team/MeetTheStaff';
import Bio from './Pages/Team/Bio';
import InstitutionalStructure from './Pages/Team/InstitutionalStructure';
import AICRelationshipPage from './Pages/Networking/AICRelationshipPage';
import NewsPage from './Pages/News/NewsPage';
import JoinUs from './Pages/Team/JoinUs';
import NewsDetails from './Pages/News/NewsDetails';
import TagPages from './Pages/News/TagPages';
import ProjectsDetails from './Pages/Projects/ProjectsDetails'
import Popup from './components/Popup';

const ConditionalHeader = () => {
  const location = useLocation();
  const isHomePage = (pathname) => {
    return ['/', '/sq', '/en', '/sq/', '/en/'].includes(pathname) || 
           /^\/(?:sq|en)\/?$/.test(pathname);
  };
  return isHomePage(location.pathname) ? <HeaderHome /> : <MainHeader />;
};

const App = () => {
  const { language, changeLanguage } = useLanguage();
  const { globalLanguage, setGlobalLanguage } = useContext(GlobalLanguageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const currentLang = location.pathname.split('/')[1];
    if (currentLang === 'sq' || currentLang === 'en') {
      changeLanguage(currentLang);
      setGlobalLanguage(currentLang);
    } else {
      navigate(`/sq${location.pathname}`, { replace: true });
    }
  }, [location, changeLanguage, setGlobalLanguage, navigate]);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timer);
  }, [location]);

  if (loading) {
    return (
      <>
        <ConditionalHeader />
        <Loading />
      </>
    );
  }

  return (
    <>
      <ConditionalHeader />
      <Routes>
        <Route path="/" element={<Navigate to="/sq" replace />} />
        <Route path="/:lang" element={<Home />} />
        <Route path="/:lang/" element={<Home />} />
        <Route path={`/:lang/${translateRoute('about-us/vision-and-mission', 'en', language)}`} element={<About />} />
        <Route path={`/:lang/${translateRoute('about-us/why-choose-aic', 'en', language)}`} element={<WhyChooseAIC />} />
        <Route path={`/:lang/${translateRoute('about-us/law-and-regulations', 'en', language)}`} element={<ByLaw />} />
        {/* Updated Transparency Route to match translations exactly */}
        <Route path={`/:lang/${translateRoute('transparency', 'en', language)}`} element={<Transparency />} />
        <Route path={`/:lang/${translateRoute('contact', 'en', language)}`} element={<Contact />} />
        <Route path={`/:lang/${translateRoute('team/meet-the-staff', 'en', language)}`} element={<MeetTheStaff />} />
        <Route path={`/:lang/${translateRoute('team/join-us', 'en', language)}`} element={<JoinUs />} />
        <Route path={`/:lang/${translateRoute('team/institutional-structure', 'en', language)}`} element={<InstitutionalStructure />} />
        <Route path={`/:lang/${translateRoute('projects', 'en', language)}`} element={<Projects />} />
        <Route path={`/:lang/${translateRoute('open-calls/closed', 'en', language)}`} element={<ClosedCalls />} />
        <Route path={`/:lang/${translateRoute('open-calls/ongoing', 'en', language)}`} element={<Ongoing />} />
        <Route path={`/:lang/${translateRoute('networking/relationship-with-line-ministers', 'en', language)}`} element={<AICRelationshipPage />} />
        <Route path={`/:lang/${translateRoute('news', 'en', language)}`} element={<NewsPage />} />
        <Route path={`/:lang/${translateRoute('team/meet-the-staff', 'en', language)}/:slug`} element={<Bio />} />
        <Route path={`/:lang/${translateRoute('news', 'en', language)}/:slug`} element={<NewsDetails />} />
        <Route path={`/:lang/${translateRoute('projects', 'en', language)}/:slug`} element={<ProjectsDetails />} />
        <Route path={`/:lang/${translateRoute('tags', 'en', language)}/:slug`} element={<TagPages />} />
      </Routes>
      <Footer />
      <Popup language={language} />
    </>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <GlobalLanguageProvider>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </GlobalLanguageProvider>
    </Router>
  );
};

export default AppWrapper;