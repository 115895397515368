import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';
import { Linkedin, Instagram, Facebook, Twitter } from 'lucide-react';
import { GlobalLanguageContext } from './LanguageSwitcher';

const Footer = () => {
  const { t, i18n } = useTranslation('footerComponent');
  const { globalLanguage } = useContext(GlobalLanguageContext);
  const [currentLanguage, setCurrentLanguage] = useState(globalLanguage);

  useEffect(() => {
    setCurrentLanguage(globalLanguage);
    i18n.changeLanguage(globalLanguage);
  }, [globalLanguage, i18n]);

  const usefulLinks = [
    { name: t('usefulLinks.aboutUs'), link: `/${currentLanguage}/${t('routes.aboutUs')}/${t('routes.visionMission')}` },
    { name: t('usefulLinks.openCalls'), link: `/${currentLanguage}/${t('routes.openCalls')}/${t('routes.ongoing')}` },
    { name: t('usefulLinks.projects'), link: `/${currentLanguage}/${t('routes.projects')}` },
    { name: t('usefulLinks.team'), link: `/${currentLanguage}/${t('routes.team')}/${t('routes.meetTheStaff')}` },
    { name: t('usefulLinks.news'), link: `/${currentLanguage}/${t('routes.news')}` },
    { name: t('usefulLinks.events'), link: `/${currentLanguage}/${t('routes.news')}` },
    { name: t('usefulLinks.contact'), link: `/${currentLanguage}/${t('routes.contact')}` }
  ];

  return (
    <div className="Footer-wrapper">
      <div className="Footer__investment-banner">
        <h2 className="Footer__banner-title">{t('investmentBanner.title')}</h2>
      
        <Link to={`/${currentLanguage}/${t('routes.contact')}`} className="Footer__invest-button">
          {t('investmentBanner.buttonText')}
        </Link>
      </div>
      
      <footer className="Footer">
        <div className="Footer__side-image Footer__side-image--left"></div>
        <div className="Footer__side-image Footer__side-image--right"></div>
        <div className="Footer__container">
          <div className="Footer__content">
            {/* Logo and Social Icons */}
            <div className="Footer__section">
              <img src={`${process.env.PUBLIC_URL}/AIClogo2.png`} alt="AIC Logo" className="Footer__logo" />
              <div className="Footer__social-icons">
                <a href={t('socialLinks.linkedin')} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                  <Linkedin className="Footer__icon" size={20} />
                </a>
                <a href={t('socialLinks.instagram')} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                  <Instagram className="Footer__icon" size={20} />
                </a>
                <a href={t('socialLinks.facebook')} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
                  <Facebook className="Footer__icon" size={20} />
                </a>
                <a href={t('socialLinks.twitter')} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
                  <Twitter className="Footer__icon" size={20} />
                </a>
              </div>
            </div>

            {/* Useful Links */}
            <div className="Footer__section">
              <h3 className="Footer__section-title">{t('usefulLinks.title')}</h3>
              <ul className="Footer__link-list">
                {usefulLinks.map((item) => (
                  <li key={item.name} className="Footer__link-item">
                    <Link to={item.link} style={{ color: 'white' }}>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Get in Touch */}
            <div className="Footer__section">
              <h3 className="Footer__section-title">{t('getInTouch.title')}</h3>
              <ul className="Footer__contact-list">
                <li className="Footer__contact-item">
                  <span className="Footer__icon">🕒</span> {t('getInTouch.workingHours')}
                </li>
                <li className="Footer__contact-item">
                  <span className="Footer__icon">✉</span> {t('getInTouch.email')}
                </li>
                <li className="Footer__contact-item">
                  <span className="Footer__icon">📍</span> {t('getInTouch.address')}
                </li>
              </ul>
            </div>
          </div>

          {/* Copyright */}
          <div className="Footer__copyright">
            <p>{t('copyright')}</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;