import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Eye, Download, FileText, FileSpreadsheet } from 'lucide-react';
import sanityClient from '../../sanity';
import { PortableText } from '@portabletext/react';

const translations = {
  en: {
    title: "Programs and Transparency",
    viewDocument: "View Document",
    downloadDocument: "Download",
    noDocumentsAvailable: "No documents are currently available.",
    documentsAvailable: "documents available",
    error: "Error loading documents. Please try again later.",
  },
  sq: {
    title: "Programi i Transparencës",
    viewDocument: "Shiko Dokumentin",
    downloadDocument: "Shkarko",
    noDocumentsAvailable: "Aktualisht nuk ka dokumente të disponueshme.",
    documentsAvailable: "dokumente në dispozicion",
    error: "Gabim në ngarkimin e dokumenteve. Ju lutemi provoni përsëri më vonë.",
  }
};

const truncateText = (text, maxLength) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

const DocumentCategoriesGrid = () => {
  const { i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const t = (key) => translations[i18n.language]?.[key] || translations['en'][key];

  useEffect(() => {
    sanityClient
      .fetch(`
        *[_type == "documentCategory"] | order(order asc) {
          _id,
          customTitle_en,
          customTitle_sq,
          contactInfo_en,
          contactInfo_sq,
          enableLink,
          categoryUrl,
          order,
          "documents": documents[] | order(order asc) {
            customTitle_en,
            customTitle_sq,
            order,
            "fileUrl_en": file_en.asset->url,
            "fileUrl_sq": file_sq.asset->url,
            "fileName_en": file_en.asset->originalFilename,
            "fileName_sq": file_sq.asset->originalFilename,
            "extension_en": file_en.asset->extension,
            "extension_sq": file_sq.asset->extension
          }
        }
      `)
      .then((result) => {
        const processedResult = result.map(category => ({
          ...category,
          documents: category.documents || []
        }));
        setCategories(processedResult);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
        setError(t('error'));
        setLoading(false);
      });
  }, [i18n.language]);

  const getFileIcon = (extension) => {
    if (!extension) return <FileText className="text-gray-500" />;
    
    switch(extension.toLowerCase()) {
      case 'pdf':
        return <FileText className="text-red-500" />;
      case 'xls':
      case 'xlsx':
        return <FileSpreadsheet className="text-green-500" />;
      case 'ppt':
      case 'pptx':
        return <FileSpreadsheet className="text-orange-500" />;
      default:
        return <FileText className="text-gray-500" />;
    }
  };

  const getCurrentLanguageData = (item) => {
    if (!item) return { title: '', fileUrl: '', fileName: '', extension: '', contactInfo: null };
    
    const lang = i18n.language;
    return {
      title: lang === 'sq' ? item.customTitle_sq || item.customTitle_en : item.customTitle_en || item.customTitle_sq,
      fileUrl: lang === 'sq' ? item.fileUrl_sq || item.fileUrl_en : item.fileUrl_en || item.fileUrl_sq,
      fileName: lang === 'sq' ? item.fileName_sq || item.fileName_en : item.fileName_en || item.fileName_sq,
      extension: lang === 'sq' ? item.extension_sq || item.extension_en : item.extension_en || item.extension_sq,
      contactInfo: lang === 'sq' ? item.contactInfo_sq || item.contactInfo_en : item.contactInfo_en || item.contactInfo_sq
    };
  };

  const isPDF = (extension) => extension?.toLowerCase() === 'pdf';

  const handleDownload = async (fileUrl, fileName) => {
    if (!fileUrl) {
      console.error('No file URL available');
      return;
    }

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName || 'document';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  if (loading) {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 rounded w-1/3 mx-auto"></div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-32 bg-gray-200 rounded-lg"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto mb-32">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">
          {t('title')}
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {categories?.map((category) => {
            if (!category) return null;
            const documents = category.documents || [];
            const { title, contactInfo } = getCurrentLanguageData(category);
            const isActive = activeCategory === category._id;
            
            const CategoryContent = () => (
              <div className="flex flex-col h-full justify-between">
                <h2 className="text-xl font-semibold line-clamp-1 mb-2">
                  {truncateText(title, 40)}
                </h2>
                <p className="text-sm opacity-75">
                  {documents.length} {t('documentsAvailable')}
                </p>
              </div>
            );

            const handleCategoryClick = (e) => {
              if (category.enableLink && category.categoryUrl) {
                e.stopPropagation();
              } else {
                setActiveCategory(isActive ? null : category._id);
              }
            };
            
            return (
              <div key={category._id} className="relative">
                <div className={`relative ${isActive ? 'z-50' : 'z-0'}`}>
                  {category.enableLink && category.categoryUrl ? (
                    <a
                      href={category.categoryUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`block w-full h-32 p-6 rounded-lg transition-all duration-500 ease-in-out transform hover:shadow-lg 
                        ${isActive
                          ? 'bg-[#e41e26] text-white shadow-xl scale-105'
                          : 'bg-white text-gray-800 border-l-4 border-[#e41e26] shadow hover:scale-102'
                        }`}
                      onClick={handleCategoryClick}
                    >
                      <CategoryContent />
                    </a>
                  ) : (
                    <button
                      onClick={handleCategoryClick}
                      className={`w-full h-32 p-6 rounded-lg transition-all duration-500 ease-in-out transform hover:shadow-lg 
                        ${isActive
                          ? 'bg-[#e41e26] text-white shadow-xl scale-105'
                          : 'bg-white text-gray-800 border-l-4 border-[#e41e26] shadow hover:scale-102'
                        }`}
                    >
                      <CategoryContent />
                    </button>
                  )}

                  {isActive && (
                    <div 
                      className="absolute left-0 w-full bg-white rounded-lg shadow-xl mt-2 transition-all duration-500 ease-in-out origin-top"
                      style={{
                        transform: 'translateY(0)',
                        opacity: 1,
                        animation: 'slideDown 0.5s ease-out'
                      }}
                    >
                      <div className="p-6">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">
                          {title}</h3>
                        {contactInfo && (
                          <div className="mb-6 prose max-w-none">
                            <PortableText value={contactInfo} />
                          </div>
                        )}
                        
                        {documents.length > 0 ? (
                          <div className="space-y-2">
                            {documents
                              .sort((a, b) => (a.order || 0) - (b.order || 0))
                              .map((doc, idx) => {
                                if (!doc) return null;
                                const { title, fileUrl, fileName, extension } = getCurrentLanguageData(doc);
                                const showPreviewIcon = isPDF(extension);

                                return (
                                  <div 
                                    key={idx}
                                    className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-all duration-200 ease-in-out group"
                                  >
                                    <div className="flex items-center gap-3 max-w-[70%]">
                                      {getFileIcon(extension)}
                                      <span className="font-medium text-gray-800 truncate">
                                        {title}
                                      </span>
                                    </div>
                                    <div className="flex gap-2 opacity-90 group-hover:opacity-100">
                                      {showPreviewIcon && fileUrl && (
                                        <a
                                          href={fileUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="inline-flex items-center justify-center p-2 text-gray-600 hover:text-[#e41e26] rounded-full hover:bg-red-50 transition-all duration-200"
                                          title={t('viewDocument')}
                                        >
                                          <Eye size={20} />
                                        </a>
                                      )}
                                      {fileUrl && (
                                        <button
                                          onClick={() => handleDownload(fileUrl, fileName)}
                                          className="inline-flex items-center justify-center p-2 text-gray-600 hover:text-[#e41e26] rounded-full hover:bg-red-50 transition-all duration-200"
                                          title={t('downloadDocument')}
                                        >
                                          <Download size={20} />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div className="text-center text-gray-500 py-4">
                            {t('noDocumentsAvailable')}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DocumentCategoriesGrid;