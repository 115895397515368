import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import { motion } from 'framer-motion';
import { GlobalLanguageContext } from '../../components/LanguageSwitcher';

const JobCard = ({ category, title, description, imageUrl, slug, language }) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    if (slug) {
      const projectsPath = language === 'en' ? 'projects' : 'projekte';
      navigate(`/${language}/${projectsPath}/${slug}`);
    }
  };

  return (
    <motion.div 
      className="flex flex-col bg-white rounded-3xl overflow-hidden shadow-lg w-full h-[300px] md:flex-row"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full h-48 md:w-1/2 md:h-full relative overflow-hidden">
        {imageUrl ? (
          <img src={imageUrl} alt={title} className="w-full h-full object-cover object-center absolute inset-0" />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-500">
            {language === 'en' ? 'No Image Available' : 'Nuk ka imazh të disponueshëm'}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between p-6 md:w-1/2">
        <div>
          <p className="text-red-600 text-lg text-left mb-2">{category}</p>
          <h3 className="font-bold text-xl text-left mb-4">{title}</h3>
          <p className="text-gray-600 text-base text-left overflow-hidden flex-grow mb-4">
            {description || (language === 'en' ? 'No description available.' : 'Nuk ka përshkrim të disponueshëm.')}
          </p>
        </div>
        <button
          className="text-red-600 text-lg bg-transparent border-none cursor-pointer p-0 text-left self-start"
          onClick={handleReadMore}
          disabled={!slug}
        >
          {language === 'en' ? 'Read More' : 'Lexo më shumë'}
        </button>
      </div>
    </motion.div>
  );
};

const OngoingCallsProject = () => {
  const [ongoingCalls, setOngoingCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { globalLanguage } = useContext(GlobalLanguageContext);

  const translations = {
    en: {
      loading: "Loading ongoing calls...",
      error: "Failed to load ongoing calls.",
      noOngoingCalls: "No ongoing calls available at the moment. Stay tuned for future opportunities!",
      subtitle: "JOIN THE FUTURE : ONGOING CALLS NOW AVAILABLE",
      title: "INVESTMENT OPPORTUNITIES AWAIT"
    },
    sq: {
      loading: "Duke ngarkuar thirrjet në vazhdim...",
      error: "Dështoi në ngarkimin e thirrjeve në vazhdim.",
      noOngoingCalls: "Aktualisht nuk ka thirrje në vazhdim. Qëndroni të informuar për mundësitë e ardhshme!",
      subtitle: "BËHUNI PJESË E SË ARDHMES : THIRRJET NË VAZHDIM TANI TË DISPONUESHME",
      title: "MUNDËSITË E INVESTIMIT JU PRESIN"
    }
  };

  useEffect(() => {
    setLoading(true);
    sanityClient
      .fetch(
        `*[_type == "projectsPost" && category == "ongoing" && isCall == true && language == $language]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          metaDescription
        }`,
        { language: globalLanguage }
      )
      .then((data) => {
        if (data.length === 0) {
          setError(translations[globalLanguage].noOngoingCalls);
        }
        setOngoingCalls(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Sanity fetch error:', err);
        setError(translations[globalLanguage].error);
        setLoading(false);
      });
  }, [globalLanguage]);

  if (loading) return <div className="text-center py-8">{translations[globalLanguage].loading}</div>;
  if (error) return <div className="text-center py-8 text-red-600">{error}</div>;

  return (
    <motion.div 
      className="bg-gray-100 p-8 bg-[url('/public/Asset 3.png')] bg-[length:300px_300px] bg-top bg-no-repeat bg-origin-content pt-20"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="text-center mb-4"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <h2 className="text-gray-600 mb-2">{translations[globalLanguage].subtitle}</h2>
        <h1 className="text-red-600 text-4xl font-bold">{translations[globalLanguage].title}</h1>
        <motion.div 
          className="w-16 h-1 bg-red-600 mx-auto mt-5"
          initial={{ width: 0 }}
          animate={{ width: 64 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        ></motion.div>
      </motion.div>
      {ongoingCalls.length > 0 ? (
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-10"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2
              }
            }
          }}
        >
          {ongoingCalls.map((call) => (
            <motion.div
              key={call.slug?.current || Math.random()}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <JobCard
                title={call.title || (globalLanguage === 'en' ? 'Untitled Project' : 'Projekt pa titull')}
                category={globalLanguage === 'en' ? "Ongoing" : "Në vazhdim"}
                description={call.metaDescription}
                imageUrl={call.mainImage?.asset?.url}
                slug={call.slug?.current}
                language={globalLanguage}
              />
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <motion.div 
          className="flex justify-center items-center min-h-[100px] text-center text-xl text-red-600 bg-gray-100 rounded-lg shadow-lg my-5 mx-auto w-full max-w-3xl"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p>{translations[globalLanguage].noOngoingCalls}</p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default OngoingCallsProject;