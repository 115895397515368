import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Presentation } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from './languageUtils';

const WelcomePopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeProject, setActiveProject] = useState(0);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showText, setShowText] = useState(true);
  const videoRef = useRef(null);
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const { language } = useLanguage();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    const checkUserStatus = () => {
      const lastVisit = localStorage.getItem('lastVisit');
      const currentTime = new Date().getTime();
      const showAgainAfter = 7 * 24 * 60 * 60 * 1000;

      if (!lastVisit || (currentTime - parseInt(lastVisit)) > showAgainAfter) {
        setTimeout(() => {
          setIsOpen(true);
          localStorage.setItem('lastVisit', currentTime.toString());
        }, 800);
      }
      setShowButton(!!lastVisit);
    };

    checkUserStatus();
  }, []);

  useEffect(() => {
    setIsVideoReady(false);
  }, [activeProject, language]);

  const playVideo = () => {
    if (videoRef.current) {
      const playPromise = videoRef.current.play();
      
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Video started playing successfully
        }).catch(error => {
          console.log("Autoplay prevented:", error);
          setTimeout(() => {
            if (videoRef.current) {
              videoRef.current.play().catch(() => {
                // Stop trying after second attempt
              });
            }
          }, 1000);
        });
      }
    }
  };

  useEffect(() => {
    if (isOpen && isVideoReady && videoRef.current) {
      playVideo();
    }
  }, [isOpen, isVideoReady, activeProject, language]);

  const content = {
    en: {
      projects: [
        {
          name: "LIFT TOWER",
          videoUrl: "../Lift_tower.mp4",
          path: "projects/open-call-for-private-partner-lift-tower"
        },
        {
          name: "VOID TOWER",
          videoUrl: "../Void_tower.mp4",
          path: "projects/call-for-void-tower"
        }
      ],
      deadline: "Application Deadline: January 20, 2025",
      buttonText: "Learn More",
      switchText: "View Next Project",
      loading: "Loading video...",
      hintText: "Check our latest development opportunities"
    },
    sq: {
      projects: [
        {
          name: "LIFT TOWER",
          videoUrl: "../Lift_tower.mp4",
          path: "projekte/thirrje-e-hapur-pr-partner-privat-lift-tower"
        },
        {
          name: "VOID TOWER",
          videoUrl: "../Void_tower.mp4",
          path: "projekte/thirrje-e-hapur-pr-partner-privat-void-tower"
        }
      ],
      deadline: "Afati i Aplikimit: 20 Janar 2025",
      buttonText: "Mëso më shumë",
      switchText: "Shiko Projektin Tjetër",
      loading: "Duke ngarkuar videon...",
      hintText: "Shikoni mundësitë tona më të fundit të zhvillimit"
    }
  };

  const selectedContent = content[language] || content.en;

  const handleVideoLoad = () => {
    setIsVideoReady(true);
    playVideo();
  };

  const switchProject = () => {
    setActiveProject((prev) => (prev === 0 ? 1 : 0));
  };

  const handleClose = () => {
    setIsOpen(false);
    setShowButton(true);
  };

  const handleLearnMore = () => {
    const projectPath = selectedContent.projects[activeProject].path;
    const fullPath = `/${language}/${projectPath}`;
    navigate(fullPath);
    handleClose();
  };

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 bg-black/70 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              ref={popupRef}
              className="bg-white w-[90%] md:w-[500px] rounded-2xl overflow-hidden shadow-2xl max-h-[85vh] md:h-[85vh]"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex flex-col h-full">
                <div className="relative bg-black w-full flex-grow" style={{ 
                  minHeight: '60vh',
                  maxHeight: '65vh'
                }}>
                  {!isVideoReady && (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
                      <div className="text-white/80">
                        {selectedContent.loading}
                      </div>
                    </div>
                  )}
                  <video 
                    ref={videoRef}
                    className={`w-full h-full object-cover ${isVideoReady ? 'opacity-100' : 'opacity-0'}`}
                    onLoadedData={handleVideoLoad}
                    onCanPlay={() => playVideo()}
                    onPause={() => playVideo()}
                    playsInline
                    muted
                    autoPlay
                    loop
                    key={`video-${activeProject}-${language}-${isOpen}`}
                  >
                    <source src={selectedContent.projects[activeProject].videoUrl} type="video/mp4" />
                  </video>
                </div>

                <div className="p-3 md:p-4">
                  <h2 className="text-lg md:text-xl font-bold text-gray-900">
                    {selectedContent.projects[activeProject].name}
                  </h2>
                  <p className="text-xs md:text-sm text-red-600 font-medium mt-1">
                    {selectedContent.deadline}
                  </p>
                </div>

                <div className="p-3 md:p-4 pt-0">
                  <div className="flex gap-2">
                    <motion.button
                      onClick={switchProject}
                      className="flex-1 px-2 md:px-3 py-2 md:py-2.5 text-xs md:text-sm border-2 border-red-600 text-red-600 rounded-xl font-medium"
                      whileTap={{ scale: 0.98 }}
                    >
                      {selectedContent.switchText}
                    </motion.button>
                    
                    <motion.button
                      onClick={handleLearnMore}
                      className="flex-1 px-2 md:px-3 py-2 md:py-2.5 text-xs md:text-sm bg-red-600 text-white rounded-xl font-medium"
                      whileTap={{ scale: 0.98 }}
                    >
                      {selectedContent.buttonText}
                    </motion.button>
                  </div>
                </div>
              </div>

              <button 
                onClick={handleClose}
                className="absolute top-2 right-2 md:top-4 md:right-4 w-7 h-7 md:w-8 md:h-8 flex items-center justify-center rounded-full bg-black/20"
              >
                <span className="text-white text-lg md:text-xl">×</span>
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showButton && !isOpen && (
          <div className="fixed bottom-4 md:bottom-6 right-4 md:right-6 flex items-center gap-3 z-40">
            <AnimatePresence>
              {showText && (
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  className="bg-white/90 text-gray-700 text-xs md:text-sm py-2 px-3 md:px-4 rounded-full shadow-md hidden md:block"
                  onAnimationComplete={() => {
                    setTimeout(() => {
                      setShowText(false);
                    }, 5000);
                  }}
                >
                  {selectedContent.hintText}
                </motion.div>
              )}
            </AnimatePresence>

            <motion.button
              className="w-10 h-10 md:w-12 md:h-12 bg-red-600 text-white rounded-full shadow-lg flex items-center justify-center hover:bg-red-700 transition-all"
              onClick={() => setIsOpen(true)}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Presentation size={18} className="md:w-5 md:h-5" />
            </motion.button>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default WelcomePopup;