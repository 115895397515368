// src/App.js
import React from 'react';
import HeaderHome from './HeaderHome';
import Redbox from "./Redbox";
import Section from "./Section";
import Project from "./Project";
import ExploreOurSection from "./ExploreOurSection";
import News from "./News";
import Pin from "./Pin";
import HomeTeam from "./HomeTeam";
import Logo from "./Logo";
import JoinUsSection from "./JoinusSection"
import HeroSection from "./HeroSection"


function Home() {
  return (
    <div className="App">
      <HeroSection/>
      <Redbox/>
      <Section />
      <Project />
      <ExploreOurSection/>
      <News/>
      <Pin/>
      <HomeTeam/>
      <JoinUsSection/>
      <Logo/>
    </div>
  );
}

export default Home;