import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import { motion } from 'framer-motion';
import { GlobalLanguageContext } from '../../components/LanguageSwitcher';

const JobCard = ({ category, title, description, imageUrl, slug, language }) => {
  const navigate = useNavigate();

  const handleReadMore = () => {
    if (slug) {
      const projectsPath = language === 'en' ? 'projects' : 'projekte';
      navigate(`/${language}/${projectsPath}/${slug}`);
    }
  };

  return (
    <motion.div 
      className="flex flex-col md:flex-row bg-white rounded-3xl overflow-hidden shadow-lg w-full h-auto md:h-[300px]"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full md:w-1/2 h-[200px] md:h-full relative">
        {imageUrl ? (
          <img src={imageUrl} alt={title} className="w-full h-full object-cover object-center absolute inset-0" />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200 text-gray-500">
            {language === 'en' ? 'No Image Available' : 'Nuk ka imazh të disponueshëm'}
          </div>
        )}
      </div>
      <div className="w-full md:w-1/2 p-6 flex flex-col justify-between">
        <div>
          <p className="text-red-600 text-lg md:text-xl mb-2 text-left">{category}</p>
          <h3 className="font-bold text-xl md:text-2xl mb-4 text-left">{title}</h3>
          <p className="text-gray-600 text-sm md:text-base text-left overflow-hidden line-clamp-3 mb-4">
            {description || (language === 'en' ? 'No description available.' : 'Nuk ka përshkrim të disponueshëm.')}
          </p>
        </div>
        <button
          className="text-red-600 text-lg md:text-xl bg-transparent border-none cursor-pointer p-0 text-left self-start"
          onClick={handleReadMore}
        >
          {language === 'en' ? 'Read More' : 'Lexo më shumë'}
        </button>
      </div>
    </motion.div>
  );
};

const ClosedCallsProject = () => {
  const [closedCalls, setClosedCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { globalLanguage } = useContext(GlobalLanguageContext);

  const translations = {
    en: {
      loading: "Loading closed calls...",
      error: "Failed to load closed calls. Please try again later.",
      noClosedCalls: "No closed calls available at the moment. Stay tuned for future opportunities!",
      subtitle: "Explore Our Closed Investment Opportunities",
      title: "These Calls Have Ended",
      category: "Closed"
    },
    sq: {
      loading: "Duke ngarkuar thirrjet e mbyllura...",
      error: "Dështoi ngarkimi i thirrjeve të mbyllura. Ju lutemi provoni përsëri më vonë.",
      noClosedCalls: "Aktualisht nuk ka thirrje të mbyllura. Qëndroni të informuar për mundësitë e ardhshme!",
      subtitle: "Eksploroni Mundësitë Tona të Mbyllura të Investimit",
      title: "Këto Thirrje Kanë Përfunduar",
      category: "Mbyllur"
    }
  };

  useEffect(() => {
    const fetchClosedCalls = async () => {
      try {
        const data = await sanityClient.fetch(
          `*[_type == "projectsPost" && category == "closed" && isCall == true && language == $language]{
            title,
            slug,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body,
            metaDescription,
            "excerpt": pt::text(body)[0...200]
          }`,
          { language: globalLanguage }
        );
        setClosedCalls(data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching closed calls:", err);
        setError(translations[globalLanguage].error);
        setLoading(false);
      }
    };

    fetchClosedCalls();
  }, [globalLanguage]);

  if (loading) return <div className="text-center py-8 text-xl">{translations[globalLanguage].loading}</div>;
  if (error) return <div className="text-center py-8 text-xl text-red-600">{error}</div>;

  return (
    <motion.div 
      className="bg-gray-100 p-4 md:p-8 bg-[url('/public/Asset 3.png')] bg-[length:150px_150px] md:bg-[length:300px_300px] bg-top bg-no-repeat bg-origin-content pt-20 md:pt-32"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className="text-center mb-6 md:mb-8"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <h2 className="text-gray-600 text-lg md:text-xl mb-2">{translations[globalLanguage].subtitle}</h2>
        <h1 className="text-red-600 text-3xl md:text-4xl font-bold">{translations[globalLanguage].title}</h1>
        <motion.div 
          className="w-16 md:w-20 h-1 bg-red-600 mx-auto mt-4 md:mt-5"
          initial={{ width: 0 }}
          animate={{ width: 'auto' }}
          transition={{ delay: 0.5, duration: 0.5 }}
        ></motion.div>
      </motion.div>
      {closedCalls.length > 0 ? (
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.2
              }
            }
          }}
        >
          {closedCalls.map((call) => (
            <motion.div
              key={call.slug.current}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 }
              }}
            >
              <JobCard
                title={call.title}
                category={translations[globalLanguage].category}
                description={call.metaDescription || call.excerpt}
                imageUrl={call.mainImage?.asset?.url}
                slug={call.slug.current}
                language={globalLanguage}
              />
            </motion.div>
          ))}
        </motion.div>
      ) : (
        <motion.div 
          className="flex justify-center items-center min-h-[100px] text-center text-xl text-red-600 bg-gray-100 rounded-lg shadow-md mx-auto w-full max-w-3xl"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <p>{translations[globalLanguage].noClosedCalls}</p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default ClosedCallsProject;